import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthModel } from '../models';

export type AuthType = AuthModel | undefined;

@Injectable({
  providedIn: 'root',
})

export class TokenService {
  private readonly authLocalStorageToken = 'authToken';

  private currentAuthData: BehaviorSubject<AuthType> = new BehaviorSubject<AuthType>(this.getAuthFromLocalStorage());

  constructor() { }

  get authData(): AuthType {
    return this.currentAuthData.value;
  }

  set authData(data: AuthType) {
    this.currentAuthData.next(data);
  }

  getToken(): string | undefined {
    return this.authData?.authToken;
  }

  setAuthData(authData: AuthModel): void {
    this.authData = authData;
    this.setAuthFromLocalStorage(authData);
  }

  private setAuthFromLocalStorage(auth: AuthModel): void {
    if (auth?.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
    }
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    const lsValue = localStorage.getItem(this.authLocalStorageToken);
    if (!lsValue) {
      return undefined;
    }
    try {
      return JSON.parse(lsValue) as AuthModel;
    } catch (error) {
      console.error('Failed to parse auth data from local storage', error);
      return undefined;
    }
  }
}
