import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private tokenService: TokenService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken();
    const isV2 = req.url.includes('/v2');
    const apiUrl = isV2 ? 'https://seller-api.ilai.io/api' : environment.apiUrl;

    req = req.clone({
      url: `${apiUrl}${req.url}`,
      setHeaders: {
        'Authorization': `Bearer ${token}`,
      },
    });

    return next.handle(req);
  }
}
